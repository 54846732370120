@font-face {
  font-family: GolriaHallelujah;
  src: url('assets/fonts/GloriaHallelujah-Regular.ttf');
  src: url('assets/fonts/GloriaHallelujah-Regular.ttf') format('truetype');
}

@font-face {
  font-family: GamjaFlower;
  src: url('assets/fonts/GamjaFlower-Regular.ttf');
  src: url('assets/fonts/GamjaFlower-Regular.ttf') format('truetype');
}

body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
  font-family: 'GamjaFlower' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 24px !important;
}

body:before {
  content: ' ';
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: url('background.jpg') no-repeat center top;
  background-size: cover;
}

/*.mainContainer{
  position: relative;
}

.mainContainer:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  background: url('background.jpg') no-repeat center top;
  background-size: contain;
}*/

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#navigationBar, #navigationMobileBar{
  margin-top: 10px;
}
#navigationBar li, #navigationMobileBar li{
  display: inline-block;
  margin-right: 15px;
}

a{
  color: black;
}

.logo a{
  font-family: 'GamjaFlower';
  color: red;
}

.navbar-nav li a.active , #navigationBar li a.active{
  font-weight: bold;
}

#mobileNav{
  position: absolute;
  top: 10px;
  left: 10px;
  padding:0px;
  z-index: 10;
}

.fullHeight, .fullHeight .container-fluid, .fullHeight .container-fluid #navbarSupportedContent{
  height: 100%
}

.zindex{
  z-index: 10;
}

#mobileNav .container-fluid{
  padding-left: 0px;
}

#navbarSupportedContent{
  background: white;
  padding-left:20px;
}

.nav-items a{
  text-decoration: none;
  color: inherit !important;
  font-size: inherit !important;
}

img.image-gallery-image{
  border-radius: 10px;
}

#mobileNav{
  display: none;
}

.navbar-toggler span {
  display: block;
  background-color: #4f4f4f;
  height: 3px;
  width: 25px;
  margin-top: 5px;
  margin-bottom: 5px;
  position: relative;
  left: 0;
  opacity: 1;
  transition: all 0.35s ease-out;
  transform-origin: center left;
}



.navbar-toggler span:nth-child(1) {
  transform: translate(0%, 0%) rotate(0deg);
}

.navbar-toggler span:nth-child(2) {
  opacity: 1;
}

.navbar-toggler span:nth-child(3) {
  transform: translate(0%, 0%) rotate(0deg);
}

.navbar-toggler span:nth-child(1) {
  margin-top: 0.3em;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  transform: translate(15%, -33%) rotate(45deg);
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  opacity: 0;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  transform: translate(15%, 33%) rotate(-45deg);
}

#fullImage{
  width: 350px;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 768px) {
  body:before {
    content: ' ';
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    background: url('background_mobile.jpg') no-repeat center top;
    background-size: cover;
  }
  img.image-gallery-image{
    width: 100%;
  }

  #navigationBar{
    display: none !important;
  }

  #mobileNav{
    display: block;
  }

  #fullImage{
    width: 100% !important;
  }

}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  img.image-gallery-image{
    box-shadow: none !important;
  }

}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  img.image-gallery-image{
    box-shadow: none !important;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  img.image-gallery-image{
  }
}